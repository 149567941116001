import React from 'react';
import Layout from '../components/Layout';
import { StaticQuery, graphql, Link } from 'gatsby';
// import { withPrefix }, Link from 'gatsby-link';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import Img from 'components/Elements/Img';
import logger from 'utils/logger';

import { Container, Row, Col } from 'reactstrap';
import { Fluid } from 'components/Containers';

import LoadingView from 'components/Loaders/LoadingView';
import { Sticky } from 'react-sticky';
import ShareBar from 'components/CTAs/ShareBar';
import { CardContact } from 'components/Cards/Cards';

class ArticleSingle extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (
      !data ||
      !data.contentfulArticle ||
      !data.allContentfulContacts
    ) { return <LoadingView />; }
    const article = data.contentfulArticle;
    const contactsData = [];
    const gutter = 8;

    data.allContentfulContacts.edges.forEach((node) => {
      const contact = node.node;
      logger.verbose(`contact`, contact);
      if (contactsData.length < 2 && contact.category.some(e => e.slug === `pr`)) {
        contactsData.push(contact);
      } else if (contactsData.length < 2 && contact.category.some(e => e.slug === `general`)) {
        contactsData.push(contact);
      }
    });
    logger.verbose(`contactsData`, contactsData);
    const bgDefault = article.thumbnail ? `white` : `indigo-900`;
    // const parsed = querystring.parse(location.search);
    // logger.debug(`querystring`, parsed);

    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO
            title={article.title}
            pathname={`/company/about-us`}
          />
          <section className={`articles bt-1`}>
            <article>
              <header className={`pt-20em lh-12 pb-15em`}>
                <h1 className={`ff-base fs-25em fw-600`}>{article.title}</h1>
              </header>
              <main className={`pb-30em article-entry`} dangerouslySetInnerHTML={{ __html: article.content.childMarkdownRemark.html }} />
              <aside>
                <Row className={`pb-30em`}>
                  <Col xs={12} className={`pb-10em`}>
                    <hr className="alpha-40" />
                  </Col>
                  <Col md={3}>
                    <img src="/img/logo-square-primary.png" alt="Apantac" style={{maxWidth: `180px`}} />
                  </Col>
                  <Col md={9} className={`ptb-5em prl-20em`}>
                    <h2 className={`fs-11em`}>About Apantac</h2>
                    <p>Apantac LLC is a leading designer and developer of high quality, cost effective image signal processing equipment. The Apantac product line has been specifically designed to provide users with a flexible and innovative technology solution for image processing, and signal extension and processing.</p>
                  </Col>
                </Row>
                <Row className={`pb-30em`}>
                  { contactsData && contactsData.map((node) => {
                    const contact = node;
                    return (
                      <Col key={`contact-${contact.id}`} md={6} className={`prl-${gutter}px pb-${gutter}px`}>
                        <CardContact {...contact} />
                      </Col>
                    );
                  })}
                </Row>
              </aside>
            </article>
          </section>
        </Fluid>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allContentfulCategory( filter: { contentTypes: { in: ["articles"] } } ){
      edges{
        node {
          id
          ... Category
        }
      }
    }
    contentfulArticle( slug: { eq: $slug } ){
      id
      ... Article
    }
    allContentfulContacts{
      edges {
        node {
          id
          ... Contact
        }
      }
    }
  }
`;
// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <Helmet titleTemplate={`%s | ${data.site.siteMetadata.title}`} defaultTitle={data.site.siteMetadata.title} />
//         <div>
//           {children}
//         </div>
//       </>
//     )}
//   />
// );

    // allContentfulCategory( filter: { contentTypes: { in: ["articles"] } } ){

// allContactsJson(limit: 500, filter: {category: {in: ["general-sales", "pr"]}}) 

// allCategoriesJson(filter:{slug: {eq: $category}})
export default ArticleSingle;
